<template>
	<div id="cases">
		<div class="vx-row mt-3 mb-6 flex justify-between">
			<div
				v-if="!is_loading && disabledSubscription"
				class="border bg-white bg-opacity-20 w-full rounded-lg p-4 mb-6"
				style="border: 1px solid var(--border)"
			>
				<p class="text-danger flex justify-center" :style="{ 'font-weight': 700 }">
					<vs-icon icon-pack="feather" icon="icon-alert-triangle" size="1.4rem" class="mr-3" />
					{{ $t("tooltip payment method") }}
				</p>

				<div class="w-full flex justify-center mt-4">
					<s-button
						icon="icon-credit-card"
						type="danger"
						class="h-10 flex justify-center items-center"
						@click="addPaymentMethod"
					>
						{{ $t("Ajouter un moyen de paiement") }}
					</s-button>
				</div>
			</div>

			<div class="w-full sm:w-1/2 flex justify-center sm:justify-between">
				<h2 class="title-sinao self-center" style="line-height: 35px">{{ $t("Mes partenaires") }}</h2>
			</div>

			<!-- v-if="loadedCases && loadedCases.length >= 8" -->
			<input
				v-model="search"
				type="text"
				placeholder="Rechercher une entreprise"
				class="w-full vs-input mt-5 search-input"
			/>

			<div class="w-full border-solid border rounded-lg mt-6 overflow-y-auto" style="border-color: var(--border)">
				<table class="w-full border-collapse border-spacing-0">
					<thead class="text-xs bg-grey-light border-b">
						<tr class="border-spacing-0">
							<th class="px-4 pt-2 text-md text-left" style="width: 48px"></th>
							<th class="px-4 pt-2 text-md text-left w-200">{{ $t("Nom") }}</th>
							<th class="px-4 pt-2 text-md font-semibold text-center">{{ $t("Api-Key") }}</th>
							<th class="px-4 pt-2 text-md font-semibold text-center">{{ $t("Abonnement") }}</th>
							<th class="px-4 pt-2 text-md font-semibold text-center">{{ $t("Début de la période") }}</th>
							<th class="px-4 pt-2 text-md font-semibold text-center">{{ $t("Statut") }}</th>
						</tr>

						<tr>
							<th colspan="6">
								<div class="flex pb-2">
									<div
										v-if="selectedCases.length > 0"
										class="flex flex-wrap justify-start items-center mr-8"
										style="gap: 1rem"
									>
										<button
											class="btn-admin text-sm px-2 flex items-center justify-center"
											:style="{ width: '140px', height: '24px' }"
											:disabled="
												selectedCases.filter(item => {
													return (
														item.subscription.plan.nickname.toLowerCase() !== 'trial' &&
														!alreadyHaveOtherPlan(item.subscription.plan)
													);
												}).length == 0
											"
											@click="cancelSubscription()"
										>
											{{ $t("Annuler l’abonnement") }}
										</button>
									</div>

									<div
										v-if="partner && partner.total_license"
										class="w-fit flex items-center ml-auto whitespace-no-wrap overflow-x-auto"
										:style="{ height: '24px' }"
									>
										<div
											v-for="(license, key, index) in partner.total_license"
											:key="index"
											class="flex items-center bg-white px-2 border"
											:class="index > 0 ? 'ml-4' : ''"
											style="line-height: 1.3rem; border-radius: 5px"
										>
											<span class="mr-1">{{ $t(key) }} : </span>
											<span>{{ license }}</span>
										</div>
										<!-- <div
											v-if="partner.upcoming"
											class="flex items-center bg-white px-2 border ml-4"
											style="line-height: 1.3rem; border-radius: 5px"
										>
											<span class="mr-1">{{ $t("Prochaine échéance") }} : </span>
											<span>{{
												new Date(partner.upcoming.next_payment_date).toLocaleDateString()
											}}</span>
										</div> -->
									</div>
								</div>
							</th>
						</tr>
					</thead>

					<tbody class="w-full">
						<tr v-for="(app, index) in filteredCases" :key="index" class="border-b">
							<td class="px-4 flex">
								<input
									v-model="app.selected"
									type="checkbox"
									class="w-5 h-5 cursor-pointer"
									@change="toggleSelection(app)"
								/>
							</td>

							<td class="pr-4 py-2" :style="{ 'max-width': '200px' }">
								<div class="flex items-center">
									<img
										:src="getImageUrl(app.organization)"
										class="rounded-full"
										height="24px"
										width="24px"
										alt
									/>

									<p class="pl-2 text-left text-md truncate">
										{{ app.organization.name }}
									</p>
								</div>
							</td>

							<td class="" :style="{ width: '105px' }">
								<button
									class="btn-admin text-sm py-2 h-8 px-2 flex items-center justify-center"
									:style="{ width: '105px' }"
									:aria-label="$t('Copier une Api-Key pour cette entreprise uniquement')"
									:disabled="app.loading_apikey"
									@click="copyApikey(app)"
								>
									{{ $t("Copier l’Api-Key") }}
								</button>
							</td>

							<td class="text-center px-4" :style="{ width: '145px' }">
								<vs-tooltip
									v-if="alreadyHaveOtherPlan(app.subscription.plan)"
									:text="$t('tooltip sinao plan')"
								>
									<p class="w-full text-left capitalize">
										{{
											$t(app.subscription.plan.name) ||
											app.subscription.plan.nickname.split("-")[0]
										}}
									</p>
								</vs-tooltip>

								<component
									:is="disabledSubscription ? 'vs-tooltip' : 'div'"
									v-else
									:text="disabledSubscription ? $t('tooltip payment method') : undefined"
								>
									<select
										v-model="app.subscription.plan.nickname"
										:disabled="disabledSelectSubscription"
										class="border-solid border rounded-lg py-1 cursor-pointer"
										:style="{ width: '145px', 'border-color': 'var(--border)' }"
										name="choose_plan"
										@focus="previousPlan = app.subscription.plan.nickname"
										@change="confirmPlanChange(app, $event)"
									>
										<option value="" disabled selected>{{ $t("Choisir un abonnement") }}</option>
										<option v-for="(plan, key) in licensePlans" :key="key" :value="key">
											<span>{{ $t(plan.name) || key }}</span>
										</option>
									</select>
								</component>
							</td>

							<td class="text-center px-4">
								<span v-if="app.subscription.upcoming_invoice">
									{{ getDate(app.subscription.upcoming_invoice.period_start) }}
								</span>
							</td>

							<td class="flex justify-end px-4">
								<vs-tooltip :text="getTooltip(app.subscription)">
									<div
										class="text-white my-1 status_icon rounded-lg p-2 flex items-center justify-center"
										:class="getStatusColor(app.subscription)"
										style="width: fit-content"
									>
										<vs-icon
											:icon-pack="'feather'"
											:icon="getStatusIcon(app.subscription)"
											size="1.4rem"
										/>
									</div>
								</vs-tooltip>
							</td>
						</tr>

						<tr v-if="filteredCases == null || filteredCases.length == 0">
							<td v-if="is_loading" colspan="6" class="text-center py-4 italic text-grey-light">
								<div ref="cases_loading" class="vs-con-loading__container" />
							</td>

							<td v-else colspan="6" class="text-center py-4 italic text-grey-light">
								{{ $t("Aucun dossier trouvé") }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters } from "vuex";
	import i18n from "../i18n";

	export default {
		name: "Cases",
		data() {
			return {
				is_loading: true,
				previousPlan: "",
				search: "",
				selectedCases: [],
				disabledSubscription: true,
				disabledSelectSubscription: true
			};
		},
		computed: {
			...mapState(["user", "loadedCases", "licensePlans", "partner"]),
			...mapGetters(["getImageUrl"]),
			filteredCases() {
				const searchTerm = (this.search ?? "").toLowerCase();

				if (searchTerm === "" || searchTerm === null || searchTerm === undefined) return this.loadedCases;

				return [...this.loadedCases].filter(app => {
					const organizationName = (app.organization.name ?? "").toLowerCase();
					const nationalId = (app.organization.national_id ?? "").toLowerCase();
					app.selected = false;
					return organizationName.includes(searchTerm) || nationalId.includes(searchTerm);
				});
			}
		},
		async mounted() {
			if (!this.loadedCases && this.user?.partner_id !== null) {
				await this.$store.dispatch("getPartnerCases", { partnerId: this.user.partner_id });
			}

			if (!this.partner && this.user?.partner_id !== null) {
				await this.$store.dispatch("getPartner", { partnerId: this.user.partner_id });
			}
			this.disabledSubscription = !this.partner?.is_payment_method ?? true;
			this.disabledSelectSubscription = this.disabledSubscription;

			if (!this.licensePlans && this.user?.partner_id !== null) {
				await this.$store.dispatch("getLicensePlans", { partnerId: this.user.partner_id });
			}

			this.is_loading = false;
		},
		methods: {
			async copyApikey(app) {
				if (app.loading_apikey) return;
				console.log(app.last_asked_apikey && new Date() - app.last_asked_apikey);
				if (app.last_asked_apikey && new Date() - app.last_asked_apikey < 5000) {
					this.$vs.notify({
						text: i18n.t("Veuillez patienter quelques secondes avant de demander une nouvelle clé"),
						color: "warning",
						time: 5000
					});
					return;
				}

				app.loading_apikey = true;
				const key = await this.$store.dispatch("getApikey", {
					partnerId: this.user.partner_id,
					app_id: app.id
				});

				navigator.clipboard.writeText(key);

				this.$vs.notify({
					text: i18n.t("La clé ’Api-Key’ a été copiée avec succès"),
					color: "success",
					time: 5000
				});

				app.loading_apikey = false;
				app.last_asked_apikey = new Date();
			},
			async confirmPlanChange(app, event) {
				const newPlan = event.target.value;

				this.$vs.dialog({
					type: "confirm",
					color: "success",
					title: i18n.t("Changement d’abonnement"),
					text: i18n.t("Êtes-vous certain de vouloir changer d’abonnement ?"),
					accept: async () => {
						try {
							this.disabledSelectSubscription = true;
							await this.$store.dispatch("updateSubscription", {
								partnerId: this.user.partner_id,
								app_id: app.id,
								plan_nickname: newPlan
							});

							const new_name_license = this.licensePlans[newPlan]?.name;
							const prev_name_license = this.licensePlans[this.previousPlan]?.name;
							this.partner.total_license[new_name_license] += 1;
							this.partner.total_license[prev_name_license] -= 1;
							this.previousPlan = newPlan;

							this.$store.commit("UPDATE_TOTAL_LICENSE", { total_license: this.partner.total_license });

							this.$vs.notify({
								text: i18n.t("L’abonnement a été mis à jour avec succès"),
								color: "success",
								time: 5000
							});
						} catch (error) {
							this.$vs.notify({
								text: i18n.t("Une erreur s’est produite lors de la mise à jour de l’abonnement"),
								color: "danger",
								time: 5000
							});

							console.error(error);
							window.location.reload();
						} finally {
							this.disabledSelectSubscription = false;
						}
					},
					cancel: (app.subscription.plan.nickname = this.previousPlan),
					acceptText: i18n.t("Oui"),
					cancelText: i18n.t("Non")
				});
			},
			toggleSelection(app) {
				if (app.selected) this.selectedCases.push(app);
				else this.selectedCases = this.selectedCases.filter(item => item.id !== app.id);
			},
			async cancelSubscription() {
				const filteredCases = this.selectedCases.filter(item => {
					return (
						item.subscription.plan.nickname.toLowerCase() !== "trial" &&
						!this.alreadyHaveOtherPlan(item.subscription.plan)
					);
				});

				this.$vs.dialog({
					type: "confirm",
					color: "danger",
					title: i18n.t("Annulation d’abonnement"),
					text: i18n.t("Êtes-vous certain de vouloir annuler ces abonnements ?"),
					accept: async () => {
						try {
							for (const app of filteredCases) {
								await this.$store.dispatch("cancelSubscription", {
									partnerId: this.user.partner_id,
									app_id: app.id
								});
							}

							if (filteredCases.length > 0) {
								this.$vs.notify({
									text: i18n.t("Les abonnements ont été annulés avec succès"),
									color: "success",
									time: 5000
								});
							}
						} catch (error) {
							this.$vs.notify({
								text: i18n.t("Une erreur s’est produite lors de l’annulation de l’abonnement"),
								color: "danger",
								time: 5000
							});

							console.error(error);
						} finally {
							this.filteredCases.forEach(app => (app.selected = false));
							this.selectedCases = [];
						}
					},
					cancel: () => {},
					acceptText: i18n.t("Oui"),
					cancelText: i18n.t("Non")
				});
			},
			getSubscriptionStatus(subscription) {
				if (!subscription.plan.active || subscription.is_restricted) return "inactive";
				if (subscription.is_trialing || subscription.upcoming_invoice) return "active";
				return "ending";
			},
			getTooltip(subscription) {
				const status = this.getSubscriptionStatus(subscription);
				return {
					active: i18n.t("Abonnement en cours"),
					ending: i18n.t("Abonnement se termine à la prochaine période"),
					inactive: i18n.t("Abonnement inactif")
				}[status];
			},
			getStatusIcon(subscription) {
				const status = this.getSubscriptionStatus(subscription);
				return {
					active: "icon-clock",
					ending: "icon-alert-triangle",
					inactive: "icon-x-circle"
				}[status];
			},
			getStatusColor(subscription) {
				const status = this.getSubscriptionStatus(subscription);
				return {
					active: "bg-primary",
					ending: "bg-warning",
					inactive: "bg-danger"
				}[status];
			},
			getDate(timestamp) {
				const date = new Date(timestamp * 1000);
				return date.toLocaleDateString();
			},
			async addPaymentMethod() {
				const uri = await this.$store.dispatch("addPaymentMethod", { app_id: this.partner.app_id });
				window.location.href = uri;
			},
			alreadyHaveOtherPlan(plan) {
				if (this.licensePlans === null) return false;
				if (plan.nickname.toLowerCase() === "trial") return false;
				if (Object.keys(this.licensePlans).includes(plan.nickname.toLowerCase())) return false;

				return true;
			}
		}
	};
</script>

<style scoped>
	.select-input,
	.search-input {
		border-radius: 6px;
	}

	.search-input:focus-within {
		border: 1px solid var(--black);
	}

	.search-input {
		width: 100%;
		margin-bottom: 0px;
		border: 1px solid var(--border);
		padding: 12px;
	}
	.w-200 {
		width: 200px;
	}

	.btn-admin:disabled {
		opacity: 0.5;
		cursor: not-allowed;
		background-color: var(--white);
	}
</style>
